import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

interface User {
    email: string;
    password: string;
}

class UserService {
    async login(user: User): Promise<any> {
        return new Promise(resolve => {
            axios.post(`/user/authenticate`, {email: user.email, password: user.password})
                .then(response => {
                    localStorage.setItem('JWT', response.data.token);
                    localStorage.setItem('User', response.data);
                    resolve({ error: false, message: 'Success!'})
                })
                .catch(error => {
                    resolve({error: true, message: 'Fehler'});
                });
        })
    }
}

export default new UserService();