import React, {useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import './Products.scss';
import ProductService from "../../service/ProductService";
import {ProductItem} from "../../components/ProductItem/ProductItem";
import history from "../../service/History";

export default function Products(): JSX.Element {
    const { id } = useParams<{id: string}>();
    const [products, setProducts] = useState([]);
    const [category, setCategory] = useState('');

    useEffect(() => {
        ProductService.getProductsByCategory(id)
            .then(result => {
                if (result.error) {
                    return;
                }
                setCategory(result.data.categoryName);
                setProducts(result.data.products);
            })
    }, [id]);

    if (products.length === 0) {
        return (
            <div>
                <h1>Loading...</h1>
            </div>
        )
    }

    return (
        <div>
            <h1>{category}</h1>
            <div className={'backButton'} onClick={() => history.goBack()}>&#8592; Zurück</div>
            <div className={'products'}>
                {products.map(({name, description, price}, index) => (
                    <ProductItem key={index} name={name} description={description} price={price}/>
                ))}
            </div>
        </div>
    );
}