import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

class CategoryService {
    async getCategories(): Promise<any> {
        return new Promise(resolve => {
            axios.get(`/category`)
                .then(response => {
                    resolve({error: false, message: 'Success', data: response.data});
                })
                .catch(error => {
                    resolve({error: true, message: 'Fehler'});
                });
        });
    }

    async getMainCategories(): Promise<any> {
        return new Promise(resolve => {
            axios.get(`/category/main`)
                .then(response => {
                    resolve({error: false, message: 'Success', data: response.data});
                })
                .catch(error => {
                    resolve({error: true, message: 'Fehler'});
                });
        });
    }

    async getProductCategories(): Promise<any> {
        return new Promise(resolve => {
            axios.get(`/category/products`)
                .then(response => {
                    resolve({error: false, message: 'Success', data: response.data});
                })
                .catch(error => {
                    resolve({error: true, message: 'Fehler'});
                });
        });
    }

    async getChildCategories(id: string): Promise<any> {
        return new Promise(resolve => {
            axios.get(`/category/${id}/children`)
                .then(response => {
                    resolve({error: false, message: 'Success', data: response.data});
                })
                .catch(error => {
                    resolve({error: true, message: 'Fehler'});
                });
        });
    }

    async createCategory(category: {name: string}): Promise<any> {
        return new Promise(resolve => {
            axios.post(`/category/add`, category)
                .then(response => {
                    resolve({error: false, message: 'Success', data: response.data});
                })
                .catch(error => {
                    resolve({error: true, message: 'Fehler'});
                });
        });
    }
}

export default new CategoryService();