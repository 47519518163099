import React, {FormEvent, useEffect, useState} from "react";
import './EditProductBox.scss';
import {Category, Product} from "../../types";
import InputField from "../Input/InputField";
import Select from "../Select/Select";
import {Button} from "../Button/Button";
import ProductService from "../../service/ProductService";
import history from "../../service/History";


type EditBoxProps = {
    product: Product;
    categories: Category[];
    open: (state: boolean) => void;
}

export function EditProductBox({product, categories, open}: EditBoxProps): JSX.Element {
    const [productName, setProductName] = useState(product.name);
    const [productDescription, setProductDescription] = useState(product.description);
    const [productPrice, setProductPrice] = useState(product.price/100);
    const [productCategory, setProductCategory] = useState(product.category.name);

    useEffect(() => {
        const actualCategory = categories.find(cat => cat.id === product.category.id);
        if (actualCategory) {
            setProductCategory(actualCategory.name);
        }
    }, [categories, product.category.id]);

    const update = async (event: FormEvent) => {
        event.preventDefault();

        if (productName === '' || productPrice === 0 || productCategory === '') {
            console.log("Felder sind leer");
            return
        }

        const category = getCategoryId(productCategory);
        if (category.id === -1) {
            console.log("Keine Kategorie");
            return;
        }

        const res = await ProductService.updateProduct(product.id, {
            name: productName,
            description: productDescription,
            price: parseInt(String(productPrice * 100)),
            categoryId: category.id
        });

        if (!res.error) {
            history.replace('/');
            history.push('./products');
        }
    }

    const getCategoryId = (category: string) => {
        return categories.find(cat => cat.name === category) || {id: -1, name: category};
    };

    return (
        <div className={'editContainer'}>
            <div className={'editBox'}>
                <b>{ productName }</b>
                <form onSubmit={update}>
                    <InputField type="text" placeholder="Produktname" value={productName}
                                onChange={(e) => setProductName(e.target.value)}/>
                    <InputField type="text" placeholder="Beschreibung" value={productDescription}
                                onChange={(e) => setProductDescription(e.target.value)}/>
                    <InputField type="text" placeholder="0.00" pattern="^[0-9]+[.]{1}[0-9]{2}|[^.][0-9]+$"
                                value={productPrice.toString()}
                                onChange={(e) => setProductPrice(+e.target.value)} />
                    <Select objects={categories} object={productCategory} onChange={setProductCategory}/>
                    <Button text="Speichern!" type="submit" />
                    <Button text="Abbrechen" type="button" onClick={() => open(false)}/>
                </form>
            </div>
        </div>
    )
}