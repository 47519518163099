import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

class ProductService {
    async getProductsByCategory(id: string): Promise<any> {
        return new Promise(resolve => {
            axios.get(`/product/category/${id}`)
                .then(response => {
                    resolve({error: false, message: 'Success', data: response.data});
                })
                .catch(error => {
                    resolve({error: true, message: 'Fehler'});
                });
        });
    }

    async getAllProducts(): Promise<any> {
        return new Promise(resolve => {
            axios.get(`/product/`)
                .then(response => {
                    resolve({error: false, message: 'Success', data: response.data});
                })
                .catch(error => {
                    resolve({error: true, message: 'Fehler'});
                });
        });
    }

    async createProduct(product: {name: string,
        description: string,
        price: number,
        categoryId: number}): Promise<any> {
        return new Promise(resolve => {
            axios.post(`/product/add`, product)
                .then(response => {
                    resolve({error: false, message: 'Success', data: response.data});
                })
                .catch(error => {
                    resolve({error: true, message: 'Fehler'});
                });
        });
    }

    async updateProduct(id: number, product: {name: string,
        description: string,
        price: number,
        categoryId: number}): Promise<any> {
        return new Promise(resolve => {
            axios.put(`/product/${id}`, product)
                .then(response => {
                    resolve({error: false, message: 'Success', data: response.data});
                })
                .catch(error => {
                    resolve({error: true, message: 'Fehler'});
                });
        });
    }

    async deleteProduct(id: number): Promise<any> {
        return new Promise(resolve => {
            axios.delete(`/product/${id}`)
                .then(response => {
                    resolve({error: false, message: 'Success', data: response.data});
                })
                .catch(error => {
                    resolve({error: true, message: 'Fehler'});
                });
        })
    }
}

export default new ProductService();