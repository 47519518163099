import React, {FormEvent, useEffect, useState} from "react";
import './Categories.scss';
import CategoryService from "../../../service/CategoryService";
import history from "../../../service/History";
import InputField from "../../../components/Input/InputField";
import {Button} from "../../../components/Button/Button";

export default function AdminCategories(): JSX.Element {
    const [categories, setCategories] = useState([]);
    const [newCategoryName, setNewCategoryName] = useState('');
    const [error, setError] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        CategoryService.getCategories()
            .then(result => {
                if (result.error) {
                    return;
                }
                setCategories(result.data.categories);
            });

    }, []);

    const create = async (event: FormEvent) => {
        event.preventDefault();

        if (newCategoryName === '') {
            setError(true);
            setMessage('Kategoriename ist leer');
            return;
        }

        const res = await CategoryService.createCategory({
            name: newCategoryName,
        });

        if (!res.error) {
            history.push('./admin');
        }

        setError(res.error);
        setMessage(res.message);
    };


    return (
        <div>
            <h1>Kategorien</h1>
            {message !== '' && (
                <div className={error ? 'error' : 'success'}>
                    <p>{message}</p>
                </div>
            )}
            <form onSubmit={create}>
                <InputField type="text" placeholder="Kategoriename"
                            onChange={(e) => setNewCategoryName(e.target.value)}/>
                <Button text="Erstellen!" type="submit" />
            </form>

            <div className={'adminCategories'}>
                <ul>
                    {categories.map(({name, id}, index) => (
                        <li key={index}>{id} - {name}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
}