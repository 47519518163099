import React from "react";
import './CategoryBox.scss';
import history from "../../service/History";

type CategoryBoxProps = {
    id: number;
    name: string;
    hasChildren: boolean;
}

export function CategoryBox({id, name, hasChildren = false}: CategoryBoxProps): JSX.Element {
    const navigate = () => {
        if (hasChildren) {
            history.push(`/category/${id}`);
            return
        }
        history.push(`/product/${id}`);
    }
    return (
        <div className={'category'} onClick={() => navigate()}>
            <div className={'categoryBox'}>
                <span>{ name }</span>
            </div>
        </div>
    )
}