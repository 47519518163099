import React, {FormEvent, useState} from "react";
import './Login.scss';
import history from "../../service/History";
import InputField from "../../components/Input/InputField";
import {Button} from "../../components/Button/Button";
import UserService from "../../service/UserService";

export default function Login(): JSX.Element {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);
    const [message, setMessage] = useState('');

    const login = async (event: FormEvent) => {
        event.preventDefault();

        const res = await UserService.login({email: email, password: password});
        if (res.error) {
            setError(res.error);
            setMessage(res.message);
            return;
        }

        setMessage(res.message);
        history.push('/admin');
    };

    return (
        <div className={'login'}>
            <h1>Login</h1>
            {message !== '' && (
                <div className={error ? 'error' : 'success'}>
                    <p>{message}</p>
                </div>
            )}
            <form className={'login-input-group'} onSubmit={login}>
                <InputField type="text" placeholder="E-Mail"
                            onChange={(e) => setEmail(e.target.value)} />
                <InputField type="password" placeholder="Passwort"
                            onChange={(e) => setPassword(e.target.value)}/>
                <Button text='Go!' type={'submit'}/>
            </form>
        </div>
    );
}