import React, {useEffect, useState} from "react";
import './Categories.scss';
import { CategoryBox } from "../../components/CategoryBox/CategoryBox";
import CategoryService from "../../service/CategoryService";
import {useParams} from "react-router-dom";
import history from "../../service/History";

export default function Categories(): JSX.Element {
    const { id } = useParams<{id: string}>();
    const [categories, setCategories] = useState([]);
    const [parentCategory, setParentCategory] = useState<{name: string}>();

    useEffect(() => {
        setCategories([]);
        setParentCategory(undefined);
        if (id) {
            CategoryService.getChildCategories(id)
                .then(result => {
                    if (result.error) {
                        return;
                    }
                    setCategories(result.data.categories);
                    setParentCategory(result.data.parent);
                });
        } else {
            CategoryService.getMainCategories()
                .then(result => {
                    if (result.error) {
                        return;
                    }
                    setCategories(result.data.categories);
                });
        }

    }, [id]);

    if (categories.length === 0) {
        return (
            <div>
                <h1>Loading...</h1>
            </div>
        )
    }


    return (
        <div>
            { parentCategory && id ?
                (
                    <>
                        <h1>{parentCategory.name}</h1>
                        <div className={'backButton'} onClick={() => history.goBack()}>&#8592; Zurück</div>
                    </>
                ) : (<h1>Rauchwolke Lounge</h1>)
            }
            <div className={'categories'}>
                {categories.map(({name, id, hasChildren}, index) => (
                    <CategoryBox name={name} id={id} key={index} hasChildren={hasChildren}/>
                ))}
            </div>
        </div>
    );
}