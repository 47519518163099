import React from "react";
import './DeleteProductBox.scss';
import {Button} from "../Button/Button";
import ProductService from "../../service/ProductService";
import history from "../../service/History";


type EditBoxProps = {
    productId: number;
    productName: string;
    open: (state: boolean) => void;
}

export function DeleteProductBox({productId, productName, open}: EditBoxProps): JSX.Element {

    const deleteProduct = async () => {
        const res = await ProductService.deleteProduct(productId)

        if (!res.error) {
            history.replace('/');
            history.push('./products');
        }
    }

    return (
        <div className={'deleteContainer'}>
            <div className={'deleteBox'}>
                <b>Löschen</b>
                <p>Willst du <b>"{ productName }"</b> wirklich löschen?</p>
                <Button text="Löschen!" type="submit" onClick={() => deleteProduct()} />
                <Button text="Abbrechen" type="button" onClick={() => open(false)} />
            </div>
        </div>
    )
}