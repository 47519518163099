import React from "react";
import './Dashboard.scss';
import history from "../../../service/History";

export default function Dashboard(): JSX.Element {


    return (
        <div>
            <h1>Dashboard</h1>
            <div className={'pages'}>
                <div className={'page'} onClick={() => history.push('./products')}>
                    <div className={'pageBox'}>
                        <span>Produke</span>
                    </div>
                </div>
            </div>
        </div>
    );
}